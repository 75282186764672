import React from "react";
import box from "assets/images/Box.svg";
import { ImageTruck, ImageBox } from "modules/common/Illustration";

function Offers() {
  return (
    <div className="my-12 lg:py-20 px-4 md:px-16 xl:px-16">
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full flex flex-col lg:w-3/5">
            <div className="flex justify-center lg:justify-start">
              <div
                className="text-center lg:text-left w-full lg:w-11/12"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <h2 className="text-3xl tracking-tight text-cape-cod-500 leading-10 font-medium lg:text-4xl sm:leading-none">
                  Pack and Ship
                </h2>
                <div className="underline mx-auto lg:ml-0 bg-cape-cod-500" />
              </div>
            </div>
            <div className="lg:mt-8">
              <div
                className="flex items-center justify-start h-16 my-12"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div className="flex items-center flex-grow-0">
                  <ImageBox />
                </div>
                <div className="flex flex-col items-center px-1">
                  <div className="text-left ml-2">
                    <h2 className="font-medium">Pack</h2>
                    <p className="text-sm lg:text-base mt-2">
                      Manage operations and prepare the package ahead of time.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center justify-start h-16 my-12"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <div className="flex items-center flex-grow-0">
                  <ImageTruck />
                </div>
                <div className="flex flex-col items-center px-1">
                  <div className="text-left ml-2">
                    <h2 className="font-medium">Ship</h2>
                    <p className="text-sm lg:text-base mt-2">
                      Get notifications on parcel pick-up by courier partner.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/5 px-4 lg:px-0 flex justify-center">
            <img src={box} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offers;
