import React from "react";
import userPlaceholder from "assets/images/icon-user.svg";
import _ from "lodash";
import { getJson } from "session";
import { Link } from "react-router-dom";
import { jsUcFirst } from "helper";
import { IconDropdown } from "../Icons";

function PrivateHeaderMenu() {
  const [isOpen, setOpen] = React.useState(false);
  const last_name = _.get(getJson("profile"), "last_name");

  const handleOnClick = (e) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  const handleClearToggle = (e) => {
    try {
      if (e.target.className.indexOf("header-dropdown-navigation") < 0) {
        setOpen(false);
      }
    } catch (error) {
      // do nothing
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClearToggle, true);
    return () => {
      document.removeEventListener("click", handleClearToggle, true);
    };
  }, []);

  return (
    <div className="flex flex-row items-center">
      <img src={userPlaceholder} alt="" />
      <div className="flex flex-col text-white ml-4 mr-6 tracking-wide">
        <span className="text-base font-medium">Logged in as</span>
        <span className="text-sm font-light">{jsUcFirst(last_name || "")}</span>
      </div>
      <div className="relative">
        <button
          type="button"
          className="header-dropdown-navigation hover:bg-cape-cod-600 focus:bg-cape-cod-600 p-1 rounded-sm"
          onClick={handleOnClick}
        >
          <IconDropdown />
        </button>
        <div className={isOpen ? "block" : "hidden"}>
          <div className="absolute border border-cape-cod-100 border-opacity-50 right-0 mt-2 py-2 w-48 bg-white rounded shadow-xl">
            <Link
              to="/profile"
              className="block px-4 py-2 text-cape-cod-500 hover:bg-cape-cod-400 hover:text-white"
            >
              <i className="fas fa-user-circle mr-2" />
              My Profile
            </Link>
            <Link
              to="/change-password"
              className="block px-4 py-2 text-cape-cod-500 hover:bg-cape-cod-400 hover:text-white"
            >
              <i className="fas fa-unlock mr-2" />
              Change Password
            </Link>
            <Link
              to="/logout"
              className="block px-4 py-2 text-cape-cod-500 hover:bg-cape-cod-400 hover:text-white"
            >
              <i className="fas fa-sign-out-alt mr-2" />
              Sign Out
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivateHeaderMenu;
