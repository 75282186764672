import React from "react";
import { useAuth } from "modules/auth/hooks";
import { Route } from "react-router-dom";
import { AlertMarker } from "modules/common/components/Alert";
import { ModalMarker } from "modules/common/components/Modal";
import PrivateRoute from "modules/partials/PrivateRoute";
import PublicRoute from "modules/partials/PublicRoute";
import Toast from "modules/common/components/Toast";
import LogoutContainer from "modules/auth/containers/LogoutContainer";
import ErrorBoundary from "modules/common/components/ErrorBoundary";
import _ from "lodash";
import { InitiateGA } from 'modules/common/components/GoogleAnalytics';

function App() {
  const auth = useAuth();
  if (_.isEmpty(auth)) return null;
  const { isAuthenticated } = auth;

  InitiateGA();

  return (
    <ErrorBoundary>
      <Toast />
      <AlertMarker />
      <ModalMarker />
      <Route path="/logout" component={LogoutContainer} />
      {!isAuthenticated && <Route path="/" component={PublicRoute} />}
      {isAuthenticated && <Route path="/" component={PrivateRoute} />}
    </ErrorBoundary>
  );
}

export default React.memo(App);
