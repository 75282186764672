import React from 'react';
import ReactPlayer from 'react-player/lazy';

const videoURL = "https://www.deliverybox.ph/deliverybox-animation.mp4";

function AdvertisementVideo() {
  return (
    <div className="w-full my-16 lg:my-20 lg:px-16">
      <div
        className="text-center mb-6"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <h2 className="text-2xl tracking-tight text-cape-cod-500 leading-10 font-medium lg:text-3xl sm:leading-none font-montserrat-bold">
          How <span className="text-kumera-500">DeliveryBox</span> Works
        </h2>
        <div className="underline bg-cape-cod-500 mx-auto" />
      </div>
      <div
        className=" flex justify-center"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {/* <iframe
          src={videoURL}
          title="description"
          className="px-2 md:px-4 w-full md:w-full h-56 sm:h-80 md:h-96 lg:h-112"
          style={{ maxWidth: "1000px" }}
        /> */}
        <ReactPlayer
          url={videoURL}
          className="px-2 md:px-4 w-full md:w-full h-56 sm:h-80 md:h-96 lg:h-112"
          style={{ maxWidth: "1000px" }}
          light="https://www.deliverybox.ph/video-thumbnail.png"
          controls
        />
      </div>
    </div>
  )
}

export default AdvertisementVideo;
