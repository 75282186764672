import React from "react";
import PropTypes from "prop-types";

const CloseButton = ({ closeToast }) => (
  <button
    type="button"
    className="focus:outline-none close"
    aria-label="Close"
    onClick={closeToast}
  >
    <span aria-hidden="true">&times;</span>
  </button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

CloseButton.defaultProps = {
  closeToast: () => {},
};

export default CloseButton;
