import React from "react";
import Restaurant from "assets/images/subscriber_Restaurant.svg";
import Mall from "assets/images/subscriber_Mall.svg";
import Vendor from "assets/images/subscriber_Vendor.svg";
import Supermarket from "assets/images/subscriber_Supermarket.svg";
import Shops from "assets/images/subscriber_Shops.svg";
import Government from "assets/images/subscriber_Governments.svg";

const list = [
  {
    id: 1,
    image: Restaurant,
    title: "Restaurant",
    content:
      "Skip multiple bookings, and save time and energy - food delivery can now be as easy as 1-2-3!",
    delay: "100",
  },
  {
    id: 2,
    image: Mall,
    title: "Mall",
    content:
      "Queueing and waiting are a thing of the past. Items big or small get straight to e-shoppers’ homes right on time.",
    delay: "100",
  },
  {
    id: 3,
    image: Vendor,
    title: "Vendor",
    content:
      "A helping hand to home-based entrepreneurs, we take the fuss out of fast shipment for you.",
    delay: "100",
  },
  {
    id: 4,
    image: Supermarket,
    title: "Supermarket",
    content:
      "Limited riders? Fret not! We drop grocery hauls at consumers’ doorstep in a heartbeat!",
    delay: "200",
  },
  {
    id: 5,
    image: Shops,
    title: "Shops",
    content:
      "SME working on a tight budget? Work instead with a courier crew - that’s on us - as if they were your own.",
    delay: "200",
  },
  {
    id: 6,
    image: Government,
    title: "Government",
    content:
      "Processes are automated for a reason. We take delays out of deliveries because the public deserves a timely service.",
    delay: "200",
  },
];

function WhoCanUse() {
  return (
    <div className="features background-features my-16 lg:my-20 lg:px-16">
      <div className="container mx-auto">
        <div
          className="text-center mb-5"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h2 className="text-2xl lg:text-3xl tracking-tight text-cape-cod-500 leading-10 font-medium sm:leading-none font-montserrat-bold">
            Who can use <span className="text-kumera-500">DeliveryBox</span>?
          </h2>
          <div className="underline bg-cape-cod-500 mx-auto" />
        </div>
        <div className="flex flex-wrap mb-4 px-4 lg:px-0">
          {list.map((item) => (
            <div
              key={item.id}
              className="flex w-full md:w-1/2 lg:w-1/3 text-center items-center sm:my-3 my-3 p-2 lg:p-3 mb-2"
              data-aos="fade-up"
              data-aos-delay={item.delay}
            >
              <img src={item.image} alt="" className="h-24 mr-4" />
              <div className="text-left w-full">
                <h4 className="text-cape-cod-500 font-medium">{item.title}</h4>
                <p className="text-sm text-gray-600">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhoCanUse;