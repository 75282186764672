import React from "react";
import { toast, ToastContainer } from "react-toastify";
import CloseButton from "./CloseButton";
import ToastTemplate from "./ToastTemplate";

export default () => (
  <ToastContainer
    position={toast.POSITION.TOP_RIGHT}
    autoClose={3000}
    closeButton={<CloseButton />}
  />
);

let toastID = null;

const clearToast = () => {
  toastID = null;
};

export const ToastDefault = (content, config) => {
  const newConfig = {
    className: "alert alert-primary",
    hideProgressBar: true,
    onClose: clearToast,
    ...config,
  };
  const component = <ToastTemplate content={content} />;
  if (toastID) {
    toast.update(toastID, { render: component, ...newConfig });
    return;
  }
  toastID = toast(component, newConfig);
};

export const ToastSuccess = (content, config) => {
  const newConfig = {
    className: "alert alert-success",
    onClose: clearToast,
    ...config,
  };
  const component = <ToastTemplate icon="check-circle" content={content} />;
  if (toastID) {
    toast.update(toastID, { render: component, ...newConfig });
    return;
  }
  toastID = toast.success(component, newConfig);
};

export const ToastError = (content, config) => {
  const newConfig = {
    className: "alert alert-danger",
    onClose: clearToast,
    ...config,
  };
  const component = (
    <ToastTemplate icon="exclamation-circle" content={content} />
  );
  if (toastID) {
    toast.update(toastID, { render: component, ...newConfig });
    return;
  }
  toastID = toast.error(component, newConfig);
};

export const ToastWarn = (content, config) => {
  const newConfig = {
    className: "alert alert-warning",
    onClose: clearToast,
    ...config,
  };
  const component = (
    <ToastTemplate icon="exclamation-triangle" content={content} />
  );
  if (toastID) {
    toast.update(toastID, { render: component, ...newConfig });
    return;
  }
  toastID = toast.warn(component, newConfig);
};

export const ToastInfo = (content, config) => {
  const newConfig = {
    className: "alert alert-info",
    onClose: clearToast,
    ...config,
  };
  const component = <ToastTemplate icon="info-circle" content={content} />;
  if (toastID) {
    toast.update(toastID, { render: component, ...newConfig });
    return;
  }
  toastID = toast.info(component, newConfig);
};
