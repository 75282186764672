import { req } from "react-reqq";
import _ from "lodash";
import { history } from "store";
import { set, get, clearAll } from "session";
import { ToastSuccess } from "modules/common/components/Toast";
import { flatIncludedToItem, removeNull, formatedSelectOption } from "helper";
import { closeModal } from "modules/common/components/Modal";
import * as c from "./constants";

export const GET_TAGGING_LIST = (params, callback = () => {}) => {
  req.list({
    key: "GET_TAGGING_LIST",
    url: "/api/v1/common/taggings",
    params,
    onSuccess: (res) => {
      const { data } = _.get(res, "response");
      callback(
        formatedSelectOption(data, "attributes.slug", "attributes.name")
      );
    },
  });
};

export const checkAuth = () => {
  const token = get("token");
  const isAuthenticated = !!token;
  const data = {
    isAuthenticated,
  };
  req.set(c.AUTHENTICATION, data);
  return { type: "CHECK_AUTH" };
};

export const myProfile = () => {
  req.get({
    key: c.MY_PROFILE,
    url: "/api/v1/profiles",
    transform: (res) => {
      const { data, included } = res;
      const args = flatIncludedToItem(data, included);
      const profile = {
        ..._.get(args, "attributes"),
        ..._.get(args, "included.profile.attributes"),
      };
      return profile;
    },
    onSuccess: (res) => {
      const { data, included } = _.get(res, "response");
      const args = flatIncludedToItem(data, included);
      const profile = {
        ..._.get(args, "attributes"),
        ..._.get(args, "included.profile.attributes"),
      };
      set("profile", JSON.stringify(removeNull(profile)));
    },
  });
};

export const login = (payload, callback = () => {}) => {
  req.post({
    key: c.LOGIN,
    url: "/api/v1/authentication",
    payload,
    onSuccess: (res) => {
      ToastSuccess("You have successfully logged in!");
      const { data, token, included } = _.get(res, "response");
      const args = flatIncludedToItem(data, included);
      const profile = {
        ..._.get(args, "attributes"),
        ..._.get(args, "included.profile.attributes"),
      };
      set("token", token);
      set("profile", JSON.stringify(removeNull(profile)));

      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
      callback();
    },
  });
};

export const register = (payload, callback = () => {}) => {
  req.post({
    key: c.REGISTER,
    url: "/api/v1/user/register",
    payload,
    onSuccess: (res) => {
      const { message } = _.get(res, "response");
      ToastSuccess(_.get(message, "details"));
      callback();
    },
  });
};

export const logout = () => {
  clearAll();
  req.reset();
  req.set(c.AUTHENTICATION, { isAuthenticated: false });
  history.push("/");
  closeModal();
  return { type: "LOGOUT" };
};

export const activateAccount = (payload, onSuccess = () => {}, onError = () => {}) => {
  req.put({
    key: c.ACTIVATE_ACCOUNT,
    url: "/api/v1/user/activate",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Your Email Address is now verified!");
      onSuccess(res?.response);
    },
    onError: (res) => {
      onError(res);
    },
  });
};

export const resendActivationLink = (payload, onSuccess = () => {}, onError = () => {}) => {
  req.put({
    key: c.RESEND_ACTIVATION_LINK,
    url: "/api/v1/user/reactivate-email",
    payload,
    onSuccess: () => onSuccess(),
    onError: (res) => onError(res),
  });
}

export const forgotPassword = (payload, callback = () => {}) => {
  req.put({
    key: c.FORGOT_PASSWORD,
    url: "/api/v1/user/forgot-password",
    payload,
    onSuccess: (res) => {
      const { success } = _.get(res, "response");
      ToastSuccess(_.get(success, "details"));
      callback();
    },
  });
};

export const resetPassword = (payload, callback = () => {}) => {
  req.put({
    key: c.RESET_PASSWORD,
    url: "/api/v1/user/reset-password",
    payload,
    onSuccess: () => {
      ToastSuccess("You have successfully changed your password");
      callback();
    },
  });
};

export const changePassword = (payload, callback = () => {}) => {
  req.put({
    key: c.CHANGE_PASSWORD,
    url: "/api/v1/profiles/change-password",
    payload,
    onSuccess: () => {
      ToastSuccess("You have successfully changed your password");
      callback();
    },
  });
};
