import React, { useEffect } from "react";
import AOS from "aos";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Loader from "modules/common/components/Loader";
import HomeContainer from "./containers/HomeContainer";
import PublicHeader from "./containers/PublicHeader";
import PublicFooter from "./containers/PublicFooter";

const ContactUsContainer = React.lazy(() =>
  import("modules/contact-us/containers/ContactUsContainer")
);
const AccountActivation = React.lazy(() =>
  import("modules/auth/containers/AccountActivationContainer")
);
// const ForgotPassword = React.lazy(() =>
//   import("modules/auth/containers/ForgotPasswordContainer")
// );
const DevelopersContainer = React.lazy(() =>
  import("modules/developers/containers/ForDevelopersContainer")
);
const DevelopersContainerOld = React.lazy(() =>
  import("modules/developers-old/containers/DevelopersContainer")
);

const ResetPasswordContainer = React.lazy(() =>
  import("modules/auth/containers/ResetPasswordContainer")
);

const TermsConditionContainer = React.lazy(() =>
  import("./containers/TermsConditionContainer")
);

const TrackOrderContainer = React.lazy(() =>
  import("modules/track-order/containers/TrackOrderContainer")
);

const portalUrl = {
  "https://deliverybox-develop.multisyscorp.io":
    "https://core-deliverybox-develop.multisyscorp.io", // DEVELOP
  "https://stg.deliverybox.ph": "https://core-stg.deliverybox.ph", // STAGING
  "https://www.deliverybox.ph": "https://core.deliverybox.ph", // PROD
};
const coreUrl = {
  "https://deliverybox-develop.multisyscorp.io": "https://core-deliverybox-develop.multisyscorp.io/login/admin", // DEVELOP
  "https://stg.deliverybox.ph": "https://core-stg.deliverybox.ph/login/admin", // STAGING
  "https://www.deliverybox.ph": "https://core.deliverybox.ph/login/admin", // PROD
};

function PublicRouter() {
  const match = useRouteMatch();

  useEffect(() => {
    AOS.init({
      offset: 10,
      duration: 600,
    })
  }, [])
  return (
    <React.Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <Switch>
        <Route path={`${match.path}features`}>
          <>
            <PublicHeader />
            <HomeContainer />
            <PublicFooter />
          </>
        </Route>
        <Route path={`${match.path}developers`}>
          <DevelopersContainer />
        </Route>
        <Route path={`${match.path}developer-old`}>
          <DevelopersContainerOld />
        </Route>
        <Route path={`${match.path}contact-us`}>
          <>
            <PublicHeader />
            <main className="flex-grow">
              <ContactUsContainer />
            </main>
            <PublicFooter />
          </>
        </Route>
        <Route path={`${match.path}terms-condition`}>
          <>
            <PublicHeader />
            <main className="flex-grow">
              <TermsConditionContainer />
            </main>
            <PublicFooter />
          </>
        </Route>
        <Route
          path="/login"
          component={() => {
            const { origin } = window.location;
            window.location.href =
              portalUrl[origin] ||
              "https://core.deliverybox.ph/login/subscriber";
            return null;
          }}
        />
        <Route
          path="/login-admin"
          component={() => {
            const { origin } = window.location;
            window.location.href =
              coreUrl[origin] ||
              "https://core.deliverybox.ph/login/admin";
            return null;
          }}
        />
        <Route path={`${match.path}track-order/:id?`}>
          <>
            <PublicHeader size="sm" />
            <TrackOrderContainer />
          </>
        </Route>

        <Route
          path={`${match.path}reset-password`}
          component={ResetPasswordContainer}
        />
        <Route
          path={`${match.path}account-activation`}
          component={AccountActivation}
        />
        {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
        <Redirect to={`${match.path}features`} />
      </Switch>
    </React.Suspense>
  );
}

export default PublicRouter;
