import React from "react";
// import lgLogo from "assets/images/large-logo.svg";
// import smLogo from "assets/images/small-logo.svg";
import Logo from "assets/images/logo-white-text.svg";
import { NavLink, useHistory } from "react-router-dom";
import PrivateHeaderMenu from "modules/common/components/PrivateHeaderMenu";
import NewUpdateContainer from "modules/common/components/NewUpdateContainer";

function Header() {
  const [toggle, setToggle] = React.useState(false);
  const history = useHistory();

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setToggle(!value);
  };

  const handleOnClick = (url) => (e) => {
    e.preventDefault();
    setToggle(false);
    history.push(url);
  };

  return (
    <header className="sticky top-0 w-full z-20">
      <nav className="flex items-center py-2 px-4 lg:py-3 lg:px-4 xl:px-16 md:px-10 bg-cape-cod-500">
        <a href="/">
          <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-0">
            <div>
              <img src={Logo} alt="" />
            </div>
            {/* <div className="hidden md:block">
              <img src={lgLogo} alt="" />
            </div>
            <div className="md:hidden">
              <img src={smLogo} alt="" />
            </div> */}
          </div>
        </a>
        <div className="block lg:hidden flex flex-grow justify-end">
          <div
            role="presentation"
            className={`hamburger${toggle ? " is-active" : ""}`}
            id="hamburger-1"
            onClick={handleOnToggle(toggle)}
          >
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
        <div className="hidden w-full lg:block flex-grow lg:flex lg:justify-between lg:items-center lg:w-auto ml-16 lg:ml-8 xl:ml-16">
          <div className="text-base xl:text-sm lg:text-xs lg:w-3/4tart">
            <NavLink
              to="/dashboard"
              activeClassName="text-koromiko-500"
              className="block mt-4 lg:mx-3 xl:mx-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
            >
              DASHBOARD
            </NavLink>
            <NavLink
              to="/transaction"
              activeClassName="text-koromiko-500"
              className="block mt-4 lg:mx-3 xl:mx-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
            >
              TRANSACTION
            </NavLink>
            <NavLink
              to="/developers"
              activeClassName="text-koromiko-500"
              className="block mt-4 lg:mx-3 xl:mx-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
            >
              FOR DEVELOPERS
            </NavLink>
            <NavLink
              to="/api-keys"
              activeClassName="text-koromiko-500"
              className="block mt-4 lg:mx-3 xl:mx-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
            >
              API KEYS
            </NavLink>
            <NavLink
              to="/contact-us"
              activeClassName="text-koromiko-500"
              className="block mt-4 lg:mx-3 xl:mx-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
            >
              CONTACT US
            </NavLink>
          </div>
          <div className="lg:w-1/3 flex justify-end">
            <PrivateHeaderMenu />
          </div>
        </div>
      </nav>
      {toggle && (
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto bg-cape-cod-700 p-6">
          <div className="text-md lg:flex-grow">
            <NavLink
              to="/dahsboard"
              activeClassName="bg-koromiko-500"
              onClick={handleOnClick("/dahsboard")}
              className="block mt-4 py-2 px-5 rounded-md text-white"
            >
              DASHBOARD
            </NavLink>
            <NavLink
              to="/transaction"
              activeClassName="bg-koromiko-500"
              onClick={handleOnClick("/transaction")}
              className="block mt-4 py-2 px-5 rounded-md text-white"
            >
              TRANSACTION
            </NavLink>
            <NavLink
              to="/developers"
              activeClassName="bg-koromiko-500"
              onClick={handleOnClick("/developers")}
              className="block mt-4 py-2 px-5 rounded-md text-white"
            >
              FOR DEVELOPERS
            </NavLink>
            <NavLink
              to="/api-keys"
              activeClassName="text-koromiko-500"
              onClick={handleOnClick("/api-keys")}
              className="block mt-4 py-2 px-5 rounded-md text-white"
            >
              API KEYS
            </NavLink>
            <NavLink
              to="/contact-us"
              activeClassName="bg-koromiko-500"
              onClick={handleOnClick("/contact-us")}
              className="block mt-4 py-2 px-5 rounded-md text-white"
            >
              CONTACT US
            </NavLink>
          </div>
          <div className="w-full mt-4 md:mt-0 flex justify-end">
            <PrivateHeaderMenu />
          </div>
        </div>
      )}
      <NewUpdateContainer />
    </header>
  );
}

export default Header;
