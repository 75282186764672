import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function FormInputCheckBox({
  name,
  onChange,
  value,
  label,
  containerClassName,
  labelClassName,
  withLabel,
  onGetValue,
  isReadOnly,
  ...props
}) {
  const handleOnCheckActive = () => {
    onChange((state) => ({ ...state, [name]: +value ? 0 : 1 }));
    if (onGetValue) {
      onGetValue(value);
    }
  };

  return (
    <div className={`${containerClassName}`}>
      <input
        id={_.get(props, "id") || name}
        name={name}
        type="checkbox"
        className="mr-2"
        onChange={handleOnCheckActive}
        checked={value}
        readOnly={isReadOnly}
        {...props}
      />
      <span />
      {withLabel && (
        <label htmlFor={_.get(props, "id") || name} className={labelClassName}>
          {label}
        </label>
      )}
    </div>
  );
}

FormInputCheckBox.defaultProps = {
  onChange: () => {},
  label: "Activate",
  containerClassName: "form-check-group flex items-center",
  labelClassName: "form-label-check",
  withLabel: true,
  onGetValue: false,
  isReadOnly: false,
  value: false,
};

FormInputCheckBox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  withLabel: PropTypes.bool,
  onGetValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
  isReadOnly: PropTypes.bool,
};

export default React.memo(FormInputCheckBox);
