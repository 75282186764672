import React from "react";
import PropTypes from "prop-types";

const ToastTemplate = ({ icon, content }) => (
  <div className="px-3 py-2  flex items-center">
    {icon && <i className={`fa fa-${icon} mr-3`} />}
    <div className="flex-grow mr-2">{content}</div>
  </div>
);

ToastTemplate.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.string.isRequired,
};

ToastTemplate.defaultProps = {
  icon: "",
};

export default ToastTemplate;
