import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Mask from "react-input-mask";

function FormInputMask({
  name,
  onChange,
  value,
  label,
  containerClassName,
  labelClassName,
  withLabel,
  required,
  onGetValue,
  ...props
}) {
  const handleChange = ({ target }) => {
    onChange((state) => ({ ...state, [name]: target.value }));
  };
  return (
    <div className={containerClassName}>
      {withLabel && (
        <label htmlFor={_.get(props, "id") || name} className={labelClassName}>
          {label} {required && <span className="text-scarlet-500">*</span>}
        </label>
      )}
      <Mask
        maskChar={null}
        mask="99-99-9999"
        id={_.get(props, "id") || name}
        name={name}
        autoComplete="off"
        type="text"
        className="form-control"
        onChange={handleChange}
        value={value}
        required={required}
        {...props}
      />
    </div>
  );
}

FormInputMask.defaultProps = {
  onChange: () => {},
  label: "Name",
  containerClassName: "form-group",
  labelClassName: "form-label",
  withLabel: true,
  required: false,
  onGetValue: false,
};

FormInputMask.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  withLabel: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onGetValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
};

export default React.memo(FormInputMask);
