/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { removeEmpty } from "helper";
import { Link } from "react-router-dom";
import FormInput from "modules/common/input/FormInput";
import FormInputCheckBox from "modules/common/input/FormInputCheckBox";
import FormInputPassword from "modules/common/input/FormInputPassword";
import FormInputMask from "modules/common/input/FormInputMask";
import FormInputAsyncSelect from "modules/common/input/FormInputAsyncSelect";
import * as actions from "../actions";

function SignUpForm({
  onClose,
  isLoading,
  handleTermsModal,
  form,
  setForm,
  handleOnSubmit,
}) {
  const handleOnGetValue = (key) => (e) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: e,
    }));
  };

  const handleLoadDeliveryOptions = (input = "", cb) => {
    const args = {
      q: input,
      type: "service",
    };
    actions.GET_TAGGING_LIST(removeEmpty(args), (data) => {
      cb(data);
    });
  };
  const handleLoadSubscriberOptions = (input = "", cb) => {
    const args = {
      q: input,
      type: "category",
    };
    actions.GET_TAGGING_LIST(removeEmpty(args), (data) => {
      cb(data);
    });
  };

  return (
    <div>
      <div className="modal-header flex">
        <div className="ml-auto">
          <button type="button" className="modal-close" onClick={onClose}>
            <svg width="20px" height="20px" viewBox="0 0 34 34">
              <path fill="transparent" d="M0 0h34v34H0z" />
              <path
                d="M24.283 6.997L27.2 9.914 10.945 26.17l-2.917-2.917z"
                fill="#888888"
              />
              <path
                d="M27.2 23.252l-2.917 2.917L8.028 9.914l2.917-2.917z"
                fill="#888888"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="bg-kumera-500 py-2 my-3">
        <h2 className="text-center text-white">
          Partner with us and connect
          <br />
          your business with our delivery services!
        </h2>
      </div>
      <div className="modal-body">
        <form className="w-full" onSubmit={handleOnSubmit}>
          <FormInput
            label="Organization Name"
            placeholder="Enter Organization Name"
            name="organization"
            required
            onChange={setForm}
            value={form.organization || ""}
          />
          <FormInput
            label="Contact Person"
            placeholder="Enter Contact Person"
            name="organization_contact_person"
            required
            onChange={setForm}
            value={form.organization_contact_person || ""}
          />
          <FormInputAsyncSelect
            cacheOptions
            defaultOptions
            required
            isMulti
            label="Delivery Type"
            placeholder="Select Delivery Type..."
            loadOptions={_.debounce(handleLoadDeliveryOptions, 1000)}
            onGetValue={handleOnGetValue("service_tags")}
            name="service_tags"
            value={_.get(form, "service_tags") || []}
          />
          <FormInputAsyncSelect
            cacheOptions
            defaultOptions
            required
            isMulti
            label="Subscriber Type"
            placeholder="Select Subscriber Type..."
            loadOptions={_.debounce(handleLoadSubscriberOptions, 1000)}
            onGetValue={handleOnGetValue("category_tags")}
            name="category_tags"
            value={_.get(form, "category_tags") || []}
          />
          <div className="w-full">
            <FormInputMask
              label="Mobile Number"
              name="mobile_number"
              onChange={setForm}
              required
              mask="+639999999999"
              placeholder="ex. +639*********"
              value={form.mobile_number || ""}
            />
          </div>
          <div className="w-full">
            <FormInput
              label="Email Address"
              subLabel="(For Email Verification)"
              type="email"
              name="email"
              onChange={setForm}
              required
              value={form.email || ""}
              placeholder="ex. juandelacruz@email.com"
            />
          </div>
          <div className="grid grid-cols-2 gap-4 gap-4">
            <FormInputPassword
              label="Password"
              name="password"
              onChange={setForm}
              required
              value={form.password || ""}
              placeholder="Enter Password"
            />
            <FormInputPassword
              label="Re-Enter Password"
              name="password_confirmation"
              onChange={setForm}
              required
              value={form.password_confirmation || ""}
              placeholder="Re-Enter Password"
            />
          </div>
          <div className="flex justify-center items-center my-4 text-cape-cod-400 text-sm">
            <FormInputCheckBox
              name="toc"
              containerClassName="form-check-group mb-0"
              className="mt-2 mr-2"
              withLabel={false}
              onChange={setForm}
              value={form.toc || ""}
              required
            />
            <p>
              <span className="mr-1 whitespace-no-wrap">I agree to the </span>
              <Link
                to="#"
                onClick={handleTermsModal}
                className="p-0 hover:text-cape-cod-500 underline"
              >
                Terms and Conditions, and Privacy Policy
              </Link>
            </p>
          </div>
          <div className="flex justify-center" />
          <div className="text-center mt-6 lg:mt-4">
            <button
              type="submit"
              disabled={isLoading}
              className="disabled:opacity-75 disabled:cursor-wait focus:outline-none py-2 lg:py-2 xl:py-3 bg-kumera-500 hover:text-kumera-500 hover:bg-white text-white text-sm md:text-md border-2 border-kumera-500 rounded-full w-full"
            >
              SIGN ME UP
            </button>
          </div>

          <div className="text-cape-cod-400 text-sm text-center mt-6 block">
            Already have an account?{" "}
            <Link to="/login" className="font-semibold hover:text-cape-cod-500">
              Log in here.
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
SignUpForm.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleTermsModal: PropTypes.instanceOf(Function).isRequired,
  // captchaID: PropTypes.bool.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  setForm: PropTypes.instanceOf(Function).isRequired,
  handleOnSubmit: PropTypes.instanceOf(Function).isRequired,
};
export default SignUpForm;
