import React from "react";
import { useHistory } from "react-router-dom";
import * as actions from "../actions";

function LogoutContainer() {
  const history = useHistory();
  React.useEffect(() => {
    actions.logout(() => {
      history.push("/");
    });
  }, [history]);

  return <div />;
}

export default LogoutContainer;
