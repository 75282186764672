import React, { useEffect } from "react";
import { useApiGet, useApiLoading } from "react-reqq";
import background from "assets/images/full-background.svg";
import SwiperContainer from "./SwiperContainer";
import { getOurPartners } from "../actions";
import { GET_OUR_PARTNERS } from "../constants";

function OurPartnersContainer() {
  const partnerList = useApiGet(GET_OUR_PARTNERS, []);
  const isLoading = useApiLoading(GET_OUR_PARTNERS, "get");

  useEffect(() => {
    getOurPartners();
  }, []);

  return isLoading || partnerList.length < 1 ? (
    ""
  ) : (
    <div
      className="relative bg-no-repeat bg-cover py-6"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 partner-shadows z-10 pointer-events-none" />
      <div className="flex flex-wrap items-center justify-center">
        <div className="w-full">
          <div data-aos="fade-up" data-aos-delay="100">
            <h4 className="lg:text-2xl text-white tracking-wide text-center block text-xl lg:font-medium">
              Our Partners
            </h4>
            <div className="underline bg-white mx-auto mb-10 mt-2" />
          </div>

          <div className="block">
            <SwiperContainer list={partnerList} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurPartnersContainer;
