import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { render } from "react-dom";
import { store, history } from "store";
import _ from "lodash";

const modalUniqueID = "my-modal-marker";

export const closeModal = () => {
  const rootElement = document.getElementById(modalUniqueID);
  const prevElem = document.activeElement;
  document.documentElement.className = "";
  document.body.className = "";
  render(<div id={modalUniqueID} />, rootElement);
  setTimeout(() => {
    try {
      prevElem.blur();
    } catch (err) {} // eslint-disable-line
  }, 100);
};

export const showModal = (options = {}) => {
  const opt = {
    title: "Modal",
    content: "Modal Message",
    withHeader: true,
    headerClassName: false,
    contentClassName: false,
    className: false,
    backdrop: false,
    blinkTitle: false,
    withCloseButton: true,
    modalType: 'default', // default || plain
    closeModal: () => {},
    ...options,
  };
  const rootElement = document.getElementById(modalUniqueID);
  if (!rootElement) {
    alert("Alert Marker not found!"); // eslint-disable-line
    return;
  }

  document.documentElement.className = "modal-open";
  document.body.className = "modal-open";
  const prevElem = document.activeElement;

  const close = () => {
    document.documentElement.className = "";
    document.body.className = "";
    render(<div id={modalUniqueID} />, rootElement);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };

  const renderContent = () => {
    if (typeof opt.content === "function") return opt.content(close);
    return opt.content;
  };

  render(
    <Provider store={store}>
      <Router history={history}>
        <div className="modal-backdrop" />
        {opt.modalType === 'default'
          ? (
            <div className="modal">
              <div
                className={`modal-container lg:mx-0 ${
                  opt.className ? ` ${opt.className}` : ""
                }`}
              >
                <div className="modal-content">
                  {opt.withHeader && (
                    <div
                      className={`modal-header flex ${
                        opt.headerClassName ? ` ${opt.headerClassName}` : ""
                      }`}
                    >
                      {!!opt.title && typeof opt.title === "string" && (
                        <p className="text-2xl font-medium mb-0">
                          {_.get(opt, "title", "")}
                        </p>
                      )}

                      {opt.withCloseButton && (
                        <div className="ml-auto">
                          <button type="button" className="modal-close" onClick={close}>
                            <svg width="20px" height="20px" viewBox="0 0 34 34">
                              <path fill="transparent" d="M0 0h34v34H0z" />
                              <path
                                d="M24.283 6.997L27.2 9.914 10.945 26.17l-2.917-2.917z"
                                fill="#888888"
                              />
                              <path
                                d="M27.2 23.252l-2.917 2.917L8.028 9.914l2.917-2.917z"
                                fill="#888888"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {renderContent()}
                </div>
              </div>
            </div>
          ) : renderContent()}

      </Router>
    </Provider>,
    rootElement
  );
};

export const ModalMarker = () => <div id={modalUniqueID} />;
