import React from "react";
import PropTypes from "prop-types";
import { Provider, useSelector } from "react-redux";
import { Router } from "react-router-dom";
import { render } from "react-dom";
import _ from "lodash";
import { store, history } from "store";

const alertUniqueID = "my-toast-marker";

export const AlertMarker = () => <div id={alertUniqueID} />;
const prevElem = document.activeElement;

const ConfirmModal = ({ opt, onClose }) => {
  const isLoading = useSelector(
    (state) =>
      Object.keys(_.get(state, "api")).join("|").indexOf("LOADING/") > -1
  );
  const renderContent = () => {
    if (typeof opt.content === "function") return opt.content(onClose);
    return opt.content;
  };
  const handleYes = () => {
    opt.onYes(onClose);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };
  const handleNo = () => {
    opt.onNo(onClose);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };
  return (
    <>
      <div className="modal">
        <div
          className={`modal-container modal-sm rounded-lg flex items-center${
            opt.className ? ` ${opt.className}` : ""
          }`}
        >
          <div className="modal-content w-full">
            {opt.title && (
              <div
                className={`modal-header mb-4${
                  opt.headerClassName ? ` ${opt.headerClassName}` : ""
                }`}
              >
                <h4 className="text-cape-cod-500 text-xl">{opt.title}</h4>
              </div>
            )}
            <div className="modal-body items-center mt-0">
              <div className="flex justify-center text-xl">
                {renderContent()}
              </div>
              <div className="flex justify-center mt-10">
                <button
                  className="mr-2 focus:outline-none text-cape-cod-500 font-medium py-2 px-10 hover:bg-cape-cod-100 rounded-full"
                  type="button"
                  onClick={handleNo}
                  disabled={isLoading}
                >
                  {opt.noLabel}
                </button>
                <button
                  className="ml-2 focus:outline-none bg-kumera-500 rounded-full px-8 py-2 font-medium text-white hover:bg-kumera-600"
                  type="button"
                  onClick={handleYes}
                  disabled={isLoading}
                >
                  {opt.yesLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop" />
      </div>
    </>
  );
};

ConfirmModal.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const confirmAlert = (options) => {
  const rootElement = document.getElementById(alertUniqueID);
  const close = () => {
    document.body.className = "";
    render(<AlertMarker />, rootElement);
  };
  const opt = {
    title: false,
    content: "Confirmation Message",
    onYes: (onClose) => onClose(),
    onNo: (onClose) => onClose(),
    yesLabel: "Yes",
    noLabel: "No",
    ...options,
  };
  if (!rootElement) {
    alert("Alert Marker not found!"); // eslint-disable-line
    return;
  }

  document.body.className = "modal-open";

  render(
    <Provider store={store}>
      <Router history={history}>
        <ConfirmModal opt={opt} onClose={close} />
      </Router>
    </Provider>,
    rootElement
  );
};
