import React from "react";
import smLogo from "assets/images/footer-small-logo.svg";
import multisysLogo from "assets/images/multisys-logo.svg";
import { Link } from "react-router-dom";
// import smFb from "assets/images/small-facebook.svg";
// import smTwitter from "assets/images/small-twitter.svg";
// import smInstagram from "assets/images/small-instagram.svg";
// import smLinkedin from "assets/images/small-linkedin.svg";

function Footer() {
  return (
    <footer className="bottom-0">
      <div className="flex items-center flex-col lg:flex-row lg:justify-between bg-cape-cod-500 py-2 lg:py-4 px-4 lg:px-16">
        <div className="flex justify-start md:justify-center lg:justify-start w-full lg:w-1/4 my-5 md:my-0">
          <img src={smLogo} alt="" />
        </div>
        <div className="flex justify-start w-full lg:w-3/4 lg:justify-end mb-5 md:mb-0">
          <div className="w-full flex flex-col md:flex-row justify-start md:justify-center lg:justify-end lg:mx-0 md:space-x-8 text-base xl:text-base lg:text-sm text-left md:text-center">
            <Link
              to="/features"
              className="mt-4 lg:mt-0 text-white hover:text-koromiko-500 text-sm"
            >
              FEATURES
            </Link>
            <Link
              to="/developers"
              className="mt-4 lg:mt-0 text-white hover:text-koromiko-500 text-sm"
            >
              FOR DEVELOPERS
            </Link>
            <Link
              to="/contact-us"
              className="mt-4 lg:mt-0 text-white hover:text-koromiko-500 text-sm"
            >
              CONTACT US
            </Link>
            <Link
              to="/terms-condition"
              className="mt-4 lg:mt-0 text-white hover:text-koromiko-500 text-sm"
            >
              TERMS AND CONDITION
            </Link>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col lg:flex-row lg:justify-between bg-cape-cod-700 py-3 px-4 lg:px-16">
        <div className="lg:justify-end lg:mt-0">
          <span className="text-2xs text-white">Developed by</span>
          <img src={multisysLogo} alt="" width="85px" />
        </div>
        <div className="flex justify-center w-full lg:w-1/2 lg:justify-end">
          <span className="text-white text-xs tracking-tight lg:tracking-normal">
            © 2020 DeliveryBox. All rights reserved.
          </span>
        </div>
        {/* <div className="lg:justify-end lg:mt-0">
          <div className="grid grid-cols-4 gap-4 lg:gap-3">
            <div className="flex items-center">
              <a
                href="/"
                className="flex-1 mt-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
              >
                <img src={smFb} alt="" />
              </a>
            </div>
            <div className="flex items-center">
              <a
                href="/"
                className="flex-1 mt-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
              >
                <img src={smTwitter} alt="" />
              </a>
            </div>
            <div className="flex items-center">
              <a
                href="/"
                className="flex-1 mt-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
              >
                <img src={smInstagram} alt="" />
              </a>
            </div>
            <div className="flex items-center">
              <a
                href="/"
                className="flex-1 mt-4 lg:inline-block lg:mt-0 text-white hover:text-koromiko-500"
              >
                <img src={smLinkedin} alt="" />
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
