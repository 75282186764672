import React from "react";
import scaleWithYou from "assets/images/scale-with-you.svg";
import usageStats from "assets/images/usage-stats.svg";
import logisticPartners from "assets/images/logistic-partners.svg";

function Features() {
  return (
    <div className="features background-features my-16 lg:my-20 lg:px-16">
      <div className="container mx-auto">
        <div
          className="text-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h2 className="text-2xl tracking-tight text-cape-cod-500 leading-10 font-medium lg:text-3xl sm:leading-none font-montserrat-bold">
            <span className="text-kumera-500">DeliveryBox</span> Features
          </h2>
          <div className="underline bg-cape-cod-500 mx-auto" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-12 sm:gap-y-3 my-3 mb-4">
          <div className="w-full text-center py-6">
            <div className="flex justify-center">
              <img
                src={scaleWithYou} alt=""
                className="hidden lg:block"
                data-aos="fade-up"
                data-aos-delay="150"
              />
              <img
                src={scaleWithYou} alt=""
                className="block lg:hidden"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <h4 className="my-2 text-xl text-cape-cod-500 font-medium">
                Automating Logistics
              </h4>
              <p className="text-sm md:text-base px-1 lg:px-0 md:px-16">
                Give quality customer experience with flexible shipping
                solutions through multiple courier partners.
              </p>
            </div>
          </div>
          <div className="w-full text-center p-6">
            <div className="flex justify-center">
              <img
                src={usageStats} alt=""
                className="hidden lg:block"
                data-aos="fade-up"
                data-aos-delay="250"
              />
              <img
                src={usageStats} alt=""
                className="block lg:hidden"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="250"
            >
              <h4 className="my-2 text-xl text-cape-cod-500 font-medium">
                Monitoring Analytics
              </h4>
              <p className="text-sm md:text-base px-1 lg:px-0 md:px-16">
                Manage transactions and shipments without the need to become a
                shipping expert.
              </p>
            </div>
          </div>
          <div className="w-full text-center py-6">
            <div className="flex justify-center">
              <img
                src={logisticPartners} alt=""
                className="hidden lg:block"
                data-aos="fade-up"
                data-aos-delay="350"
              />
              <img
                src={logisticPartners} alt=""
                className="block lg:hidden"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="350"
            >
              <h4 className="my-2 text-xl text-cape-cod-500 font-medium">
                Managing Transactions
              </h4>
              <p className="text-sm md:text-base px-1 lg:px-0 md:px-16">
                Simplify business operations by providing seamless shipping
                experience to customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
