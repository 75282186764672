import React from "react";
import _ from "lodash";
import moment from "moment";
import sanitizeHtml from "sanitize-html";
import DOMPurify from "dompurify";

export const isNumber = (number) => {
  if (_.isEmpty(`${number}`)) return true;
  // const regexp = /^[0-9]+([,.][0-9]+)?$/g;
  const regexp = /^[0-9.]*$/;
  return regexp.test(number);
};

export const objectToUpperCase = (params) => {
  const newObjt = {};
  Object.keys(params).map((item) => {
    const x = "";
    newObjt[item] =
      params[item] && typeof params[item] === "string"
        ? `${params[item].toUpperCase()}`
        : params[item];
    return x;
  });
  return newObjt;
};

export const stringToUpperCase = (string) => {
  if (!_.isNil(string) && typeof string === "string") {
    return string.toUpperCase();
  }
  return "";
};

export const jsUcFirst = (string) =>
  string
    .toLowerCase()
    .replace(new RegExp("(?:\\b|_)([a-z])", "g"), (e) => e.toUpperCase());

export const jsUcOnlyFirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getFirstMessage = (data) => {
  if (!data) return "";
  let firstMessage = "";
  let x = 0;

  Object.keys(data).map((i) => {
    if (x === 0) {
      firstMessage =
        _.get(data[i], "details") ||
        _.get(data[i], "detail") ||
        _.get(data[i], "message");
    }
    x += 1;
    return x;
  });
  return jsUcOnlyFirst(firstMessage);
};

export const removeEmpty = (obj) =>
  _.omitBy(obj, (x) => x === "" || _.isEmpty(x));

export const removeNull = (obj) =>
  _.omitBy(obj, (x) => typeof x === "undefined" || x === null);

export const formatedSelectOption = (
  list = [],
  value = "id",
  label = "name",
  isToUpperCase = true
) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: _.get(item, `${value}`),
      label: isToUpperCase
        ? _.get(item, `${label}`).toUpperCase()
        : _.get(item, `${label}`),
      data: { ...item },
      id: _.get(item, `${value}`),
    });
  });
  return newData;
};

export const isDesktop = () =>
  !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const numberWithCommas = (x = 0) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const scrollTo = (id) => {
  try {
    const elem = document.getElementById(id);
    if (id) elem.scrollIntoView({ behavior: "smooth" });
  } catch (err) {
    // do nothing...
  }
};

export const sanitizer = (data) => {
  const cleanData = sanitizeHtml(data, {
    allowedTags: [
      "b",
      "i",
      "em",
      "strong",
      "normal",
      "a",
      "p",
      "ul",
      "li",
      "ol",
      "div",
      "img",
      "span",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "sup",
      "sub",
      "ins",
      "del",
      "code",
      "figure",
      "blockquote",
      "table",
      "tbody",
      "tr",
      "td",
      "th",
      "thead",
      "dd",
      "dt",
      "figcaption",
      "s",
      "i",
      "u",
      "mark",
      "oembed",
      "iframe",
    ],
    selfClosing: ["img", "br"],
    allowedAttributes: {
      a: ["href", "target", "class"],
      img: ["src", "style", "class"],
      p: ["class", "style"],
      span: ["class", "style"],
      ol: ["class", "style"],
      ul: ["class", "style"],
      li: ["class", "style"],
      blockquote: ["class", "style"],
      table: ["class", "style"],
      tbody: ["class", "style"],
      tr: ["class", "style"],
      td: ["class", "style"],
      th: ["class", "style"],
      thead: ["class", "style"],
      dd: ["class", "style"],
      dt: ["class", "style"],
      figure: ["class", "style"],
      figcaption: ["class", "style"],
      mark: ["class", "style"],
      // oembed: ['url', 'class'],
      div: ["class", "style", "data-oembed-url", "oembed"],
      // iframe: ['class', 'style', 'frameborder', 'src', 'allow', 'allowfullscreen'],
    },
  });

  return cleanData;
};

export const hideMobileNumber = (mobileNumber) => {
  if (_.isEmpty(mobileNumber)) {
    return "";
  }

  return mobileNumber.replace(/(\d{1})\d{7}/, "$1*******");
};

export const formatDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return "-";
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return "-";
  return moment(d).format(format);
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const loadIncludedToList = (items, included) =>
  items.map((x) => {
    const rowIncluded = {};
    _.forOwn(x.relationships, (v, k) => {
      rowIncluded[k] = Array.isArray(v.data)
        ? v.data.map(
            (z) =>
              included.find(
                (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
              ) || {}
          )
        : included.find(
            (y) =>
              y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
          ) || {};
    });
    return { ...x, included: rowIncluded };
  });

export const flatIncludedToItem = (x, included) => {
  const rowIncluded = {};
  _.forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
            ) || {}
        )
      : included.find(
          (y) =>
            y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
        ) || {};
  });
  return { ...x, included: rowIncluded };
};

export const removeObjectToList = (list = [], id = "", withId = false) => {
  let newList = [];
  if (withId) {
    newList = list.filter((item) => `${item.id}` !== `${id}`);
    return newList;
  }
  newList = list.filter((item) => `${item}` !== `${id}`);
  return newList;
};

export const insertUpdateObjectToList = (list = [], data = {}) => {
  const newList = list.map((item) => {
    if (`${data.id}` !== `${item.id}`) return item;
    return _.assign({}, item, data);
  });

  return newList;
};

export const createRecursiveArr = (items, id = null, link = "parent_id") =>
  items
    .filter((item) => `${_.get(item, `${link}`)}` === `${id}`)
    .map((item) => ({
      ...item,
      children: createRecursiveArr(items, item.id, link),
    }));

export const makeArray = (count, content) => {
  const result = [];
  if (typeof content === "function") {
    for (let i = 0; i < count; i += 1) {
      result.push(content(i));
    }
  } else {
    for (let i = 0; i < count; i += 1) {
      result.push(content);
    }
  }
  return result;
};

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          const x = params;
          const [key, value] = param.split("=");
          x[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
          return x;
        }, {})
    : {};
};

export const objectToQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const deriveOptionsFrom = (arrayOfString) => {
  return arrayOfString.map((status) => {
    return {
      value: status,
      label: _.startCase(_.toLower(_.replace(status, /[_]/g, " "))),
    };
  });
};

export const replaceText = (value, replace) => {
  if (value) {
    return value.replaceAll(replace, "");
  }
  return "";
};

export const returnTrimmedSpaces = (obj, keys) => {
  const newObject = {};

  if (keys === 'ALL') {
    _.forOwn(obj, (v, k) => {
      newObject[k] = (typeof v === 'string')
        ? v.trim()
        : v;
    });
    return newObject;
  }
  if (Array.isArray(keys)) {
    _.forOwn(obj, (v, k) => {
      newObject[k] = (keys.indexOf(k) > -1 && typeof v === 'string')
        ? v.trim()
        : v;
    });
    return newObject;
  }
  return false;
};

export const renderDangerous = (content) => {
  if (content) {
    return (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
    );
  }
  return '';
};

export const sortDateArray = (list, key, order) => {
  // order = asc/desc
  if ((list || []).length < 1) return [];
  const newData = _.orderBy(list, (item) => {
    return moment(item[key]).format('YYYY-MM-DD hh:mm:ss');
  }, [order]);

  return newData;
};
