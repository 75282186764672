import React from "react";
import { useServiceWorker } from "useServiceWorker";

function NewUpdateContainer() {
  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  const onUpdateAssets = (e) => {
    e.preventDefault();
    updateAssets();
  };

  if (isUpdateAvailable) {
    return (
      <div
        onClick={onUpdateAssets}
        role="presentation"
        className="block w-full z-10 bg-kumera-500 rounded-b-lg md:rounded-b-full cursor-pointer"
      >
        <div className="container mx-auto h-32 text-center lg:h-16 flex justify-center items-center">
          <h4 className="text-white text-lg">
            Good day! We have some new updates (Click this tab to update)
          </h4>
        </div>
      </div>
    );
  }
  return null;
}

export default NewUpdateContainer;
