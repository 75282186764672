/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import { returnTrimmedSpaces, stringToUpperCase } from "helper";
import uuid from "react-uuid";
import TermsAndCondition from "modules/auth/containers/TermsAndCondition";
import * as actions from "../actions";
import * as c from "../constants";
import SignUpForm from "./SignUpForm";

const INIT_FORM = {
  email: "",
  mobile_number: "",
  organization: "",
  organization_address: "",
  organization_contact_person: "",
  organization_contact: null,
  password: "",
  password_confirmation: "",
  toc: 0,
  category_tags: [],
  category_type: "category",
  service_tags: [],
  service_type: "service",
  captcha: "",
};

function SignupModal({ onClose }) {
  const [captchaID, setCaptchaID] = useState(false);
  const [currentBody, setCurrentBody] = useState('REGISTER'); // REGISTER || TERMS
  const [form, setForm] = useState(INIT_FORM);
  const isLoading = useApiLoading(c.REGISTER, "post");

  const siteKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;

  const loadRecaptchaScript = () => {
    return new Promise((resolve) => {
      if (document.getElementById("recaptcha-script")) {
        resolve();
        return;
      }
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.id = "recaptcha-script";
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  const signup = (token) => {
    if (isLoading) return;
    const payload = {
      ...form,
      toc: +_.get(form, "toc", 0) === 1 ? "1" : "0",
      organization: stringToUpperCase(_.get(form, "organization", "")),
      category_tags: _.get(form, "category_tags", []).map((item) => item.value),
      service_tags: _.get(form, "service_tags", []).map((item) => item.value),
      ["g-recaptcha"]: token, // eslint-disable-line
    };
    const newPayload = returnTrimmedSpaces(payload, "ALL");
    actions.register(_.omit(newPayload, ""), () => {
      setForm(INIT_FORM);
      setCaptchaID(uuid());
      onClose();
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await loadRecaptchaScript();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action: "submit" }).then((token) => {
        signup(token);
      });
    });
  };

  const handleTermsModalSelection = (value) => {
    setCurrentBody("REGISTER");
    setForm({
      ...form,
      toc: value,
    })
  };

  return (
    <div className="modal">
      <div
        className={`modal-container lg:mx-0 ${
          currentBody === "TERMS" && "modal-lg"
        }`}
      >
        <div className="modal-content">
          {currentBody === "REGISTER" && (
            <SignUpForm
              onClose={onClose}
              isLoading={isLoading}
              handleTermsModal={() => setCurrentBody("TERMS")}
              captchaID={captchaID}
              form={form}
              setForm={setForm}
              handleOnSubmit={handleOnSubmit}
            />
          )}
          {currentBody === "TERMS" && (
            <TermsAndCondition
              handleTermsModalSelection={handleTermsModalSelection}
              handleSignUpModal={() => setCurrentBody("REGISTER")}
            />
          )}
        </div>
      </div>
    </div>
  );
}
SignupModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};
export default SignupModal;
