import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || "";

export const InitiateGA = () => {
  if (TRACKING_ID) ReactGA.initialize(TRACKING_ID);
  if (!TRACKING_ID)
    console.warn({ message: "Google Analytics tracking ID is missing" }); // eslint-disable-line no-console

}

export const logPageView = (page = '') => {
  if (TRACKING_ID) {
    ReactGA.set({
      page: page || window.location.pathname,
    });
    ReactGA.send({
      hitType: "pageview",
      page: page || window.location.pathname,
    });
  }

}

