import React from "react";
import { useHistory } from "react-router-dom";
import { useApiLoading } from "react-reqq";
import PropTypes from "prop-types";
// import { showModal } from "modules/common/components/Modal";
import * as c from "../constants";

function SignInContainer({ isShrink }) {
  const history = useHistory();
  const isLoading = useApiLoading(c.LOGIN, "post");

  // const handleSignupModal = () => {
  //   showModal({
  //     title: "",
  //     className: "modal-md",
  //     content: (onClose) => <SignupModal onClose={onClose} />,
  //   });
  // };

  return (
    <>
      {isShrink ? (
        <div className="flex mt-4">
          <button
            type="button"
            disabled={isLoading}
            className="disabled:opacity-75 disabled:cursor-wait focus:outline-none px-8 md:px-10 py-2 bg-kumera-500 hover:text-kumera-500 hover:bg-white text-white text-sm font-semibold border-2 border-kumera-500 rounded-full flex-none w-full"
            onClick={() => history.push("/login")}
          >
            LOGIN
          </button>
          {/* <button
            type="button"
            className="disabled:opacity-75 disabled:cursor-wait focus:outline-none px-8 md:px-10 py-1 bg-white hover:text-white hover:bg-kumera-500 text-kumera-500 text-sm font-semibold border-2 border-kumera-500 rounded-full flex-none ml-1 w-1/2"
            onClick={handleSignupModal}
          >
            SIGN UP
          </button> */}
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <div>
            <button
              type="button"
              disabled={isLoading}
              className="disabled:opacity-75 disabled:cursor-wait focus:outline-none px-10 py-2 lg:py-1 lg:px-6 xl:px-10 xl:py-1 bg-kumera-500 hover:text-kumera-500 hover:bg-white text-white text-sm border-2 border-kumera-500 rounded-full flex-none"
              onClick={() => history.push("/login")}
            >
              LOGIN
            </button>
            {/* <button
              type="button"
              className="disabled:opacity-75 disabled:cursor-wait focus:outline-none px-10 py-2 lg:py-2 lg:px-6 xl:px-10 xl:py-2 bg-white hover:text-white hover:bg-kumera-500 text-kumera-500 text-sm border-2 border-kumera-500 hover:border-kumera-500 rounded-full flex-none ml-2"
              onClick={handleSignupModal}
            >
              SIGN UP
            </button> */}
          </div>
        </div>
      )}
    </>
  );
}

SignInContainer.defaultProps = {
  isShrink: false,
};

SignInContainer.propTypes = {
  isShrink: PropTypes.bool,
};

export default React.memo(SignInContainer);
