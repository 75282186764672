import { req } from "react-reqq";
import _ from "lodash";
import * as c from "./constants";

export const getOurPartners = () => {
  req.get({
    key: c.GET_OUR_PARTNERS,
    url: "/api/v1/active-partners",
    params: { per_page: 100000 },
    transform: (res) => {
      const formattedList = _.get(res, "data", []).map((item) => ({
        id: +item?.id,
        title: item?.attributes?.name,
        img: item?.attributes?.banner_logo,
      }));

      return formattedList.filter((item) => !_.isNil(item.img));
    },
  });
};
