import React from "react";
import Hero from "modules/landing/Hero";
import Features from "modules/landing/Features";
import WhoCanUse from "modules/landing/WhoCanUse";
import AdvertisementVideo from "modules/landing/AdvertisementVideo";
import OurPartners from "modules/landing/our-partners/containers/OurPartnersContainer";
// import PlatForm from "modules/platform/containers/PlatFormContainer";
import Offers from "modules/landing/Offers";
import { useDocumentTitle } from "modules/common/hook";

function HomeContainer() {
  useDocumentTitle("Delivery Box | Home");
  return (
    <>
      <Hero />
      <Features />
      <AdvertisementVideo />
      <WhoCanUse />
      <OurPartners />
      {/* <PlatForm /> */}
      <Offers />
    </>
  );
}

export default HomeContainer;