import { configureApi } from "react-reqq";
import { ToastError } from "modules/common/components/Toast";
import _ from "lodash";
import { createBrowserHistory } from "history";
import { getFirstMessage } from "helper";

export const history = createBrowserHistory();
export const rootApi = process.env.REACT_APP_END_POINT;

const cleanUpMessage = (message) => {
  const text1 = message.replace("<span>", "");
  const text2 = text1.replace("</span>", "");
  return text2;
};

export const store = configureApi({
  endpoint: rootApi,
  requestHeaders: () => {
    const token = sessionStorage.getItem("token");
    if (!token) return {};
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  onError: (err) => {
    const response = _.get(err, "response");
    if (!response) return;
    const errors = _.get(response, 'errors') || [];

    const rawErrMsg = getFirstMessage(errors);
    const errMsg = cleanUpMessage(rawErrMsg);

    if (err.status === 422) {
      ToastError(errMsg);
      return;
    }
    // if (err.status === 401) {
    //   const message = _.get(err, "response.errors.0.detail");
    //   if (message === "Token has expired or invalid.") {
    //     ToastError("Session has expired or invalid.");
    //     history.push("/logout");
    //     return;
    //   }
    //   if (_.get(err, "response.message", "").indexOf("Bad token") > -1) {
    //     ToastError("Unauthorized Account.");
    //     history.push("/logout");
    //     return;
    //   }
    //   const { errors = [] } = _.get(err, "response");
    //   ToastError(errMsg);
    //   return;
    // }
    if (err.status === 500) {
      ToastError(errMsg || "Internal Server Error!");
      return;
    }
    ToastError(errMsg);
  },
});