export const sessionId = () => {
  try {
    return {
      auth_code: sessionStorage.getItem("token"),
    };
  } catch (error) {
    console.log(error); // eslint-disable-line
    return {};
  }
};

export const clear = () => sessionStorage.clear();

export const set = (key, value) => sessionStorage.setItem(key, value);

export const get = (key) => sessionStorage.getItem(key);

export const getJson = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key)) || {};
  } catch (err) {
    return {};
  }
};

export const clearAll = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const removeItem = (key) => sessionStorage.removeItem(key);
