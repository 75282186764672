/* eslint-disable no-use-before-define */
import React from "react";
import Boxes from "assets/images/Boxes.svg";
import { showModal } from "modules/common/components/Modal";
import SignUpModalContainer from "modules/auth/containers/SignUpModalContainer";

function Hero() {
  const handleSignupModal = () => {
    showModal({
      title: "",
      className: "",
      modalType: "plain",
      withCloseButton: false,
      content: (onClose) => <SignUpModalContainer onClose={onClose} />,
    });
  };

  return (
    <div className="background-hero flex flex-wrap-reverse lg:flex-wrap py-16 lg:py-0 pt-32 lg:pt-16">
      <div className="hero-background-shadow" />
      <div className="flex items-center justify-center xl:justify-center w-full lg:w-5/12 xl:w-5/12 px-6 md:pl-10 lg:pl-16 xl:pl-32">
        <div
          className="w-full"
        >
          <div
            className="w-full hidden lg:block mb-3"
            data-aos="fade-down"
            data-aos-delay="250"
          >
            <img alt="boxes" src={Boxes} className="h-8 md:h-10 lg:h-12" />
          </div>
          <div
            className="w-full text-center lg:text-left mt-8 lg:mt-0"
            data-aos="fade-down"
            data-aos-delay="250"
          >
            <h2 className="home-title tracking-wide leading-10 font-medium text-3xl md:text-5xl xl:text-5xl sm:leading-none">
              <span className="font-medium text-cape-cod-600">Smart</span>{" "}
              <span className="font-medium text-kumera-500">Delivery</span>
            </h2>
          </div>
          <div
            className="text-center lg:text-left mt-5 md:mt-10 lg:mt-0"
            data-aos="fade-down"
            data-aos-delay="250"
          >
            <p className="mt-5 md:mt-8 text-cape-cod-600 text-base sm:mt-5 sm:text-base sm:max-w-xl sm:mx-auto md:text-base lg:mx-0">
              We empower your business to deliver goods door-to-door without the
              hassle. Connect with thousands of riders and delivery vehicles
              through a single API.
            </p>
          </div>
          <div
            className="w-full mt-6 md:mt-10 text-center lg:text-left"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <button
              type="button"
              onClick={handleSignupModal}
              className="disabled:opacity-75 disabled:cursor-wait focus:outline-none px-10 md:px-12 py-2 lg:py-2 xl:py-3 bg-kumera-500 text-sm lg:text-lg hover:text-kumera-500 hover:bg-white text-white border-2 border-kumera-500 rounded-full flex-none"
            >
              PARTNER WITH US!
            </button>
          </div>
        </div>
      </div>

      <div className="hidden lg:flex justify-center w-full lg:w-7/12 xl:w-7/12 h-64 sm:h-72 md:h-80 lg:h-full lg:pt-10 px-6 lg:pl-12 xl:pl-16 md:pr-10 lg:pr-16 xl:pr-24">
        <div
          className="landing-img w-full lg:h-full"
          data-aos="fade-left"
          data-aos-delay="250"
        />
      </div>
      <div className="flex lg:hidden justify-center w-full lg:w-7/12 xl:w-7/12 h-64 sm:h-72 md:h-80 lg:h-full lg:pt-10 px-6 lg:pl-12 xl:pl-16 md:pr-10 lg:pr-16 xl:pr-24">
        <div
          className="landing-img w-full lg:h-full"
          data-aos="fade"
          data-aos-delay="250"
        />
      </div>
    </div>
  );
}

export default Hero;