import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function FormInput({
  name,
  onChange,
  value,
  label,
  subLabel,
  containerClassName,
  labelClassName,
  withLabel,
  required,
  type,
  onGetValue,
  ...props
}) {
  const handleChange = React.useCallback(
    ({ target }) => {
      onChange((state) => ({ ...state, [name]: target.value }));
      if (onGetValue) {
        onGetValue(target.value);
      }
    },
    [name, onChange, onGetValue]
  );

  return (
    <div className={containerClassName}>
      {withLabel && (
        <label htmlFor={_.get(props, "id") || name} className={labelClassName}>
          <div>
            {label} {required && <span className="text-scarlet-500">*</span>}
          </div>
          {subLabel && (
            <div className="ml-auto text-cape-cod-200 text-xs font-light">
              {" "}
              {subLabel}
            </div>
          )}
        </label>
      )}
      <input
        id={_.get(props, "id") || name}
        type={type}
        className="form-control"
        autoComplete="off"
        onChange={handleChange}
        value={value}
        required={required}
        {...props}
      />
    </div>
  );
}

FormInput.defaultProps = {
  onChange: () => {},
  label: "Name",
  subLabel: "",
  containerClassName: "form-group",
  labelClassName: "form-label flex",
  withLabel: true,
  required: false,
  type: "text",
  onGetValue: false,
};

FormInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subLabel: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  withLabel: PropTypes.bool,
  required: PropTypes.bool,
  onGetValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
};

export default React.memo(FormInput);
