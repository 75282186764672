import React, { useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import _ from "lodash";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs, Autoplay } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "../style.scss";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay]);

const sizes = {
  size_400: { perView: 1, increment: 0 },
  size_640: { perView: 3, increment: 1 },
  size_1024: { perView: 5, increment: 2 },
};

function SwiperContainer({ list }) {
  const [activeSlide, setActiveSlide] = useState(null);

  const slides = list.map((item) => {
    return (
      <SwiperSlide
        key={`partner-${item.id}`}
        tag="div"
        className="flex justify-center"
      >
        <figure>
          <div
            className="h-full items-start"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="bg-white flex items-center justify-center rounded-lg px-4 py-2 mb-5 h-20 w-48 xl:h-24 xl:w-64">
              <img
                src={item.img}
                style={{ listStyle: "none" }}
                alt={`partner ${item.id}`}
                className="max-h-full max-w-full"
              />
            </div>
          </div>
        </figure>
      </SwiperSlide>
    );
  });

  // to select middle card
  const handleOnSlide = (res) => {
    const currentBreakpoint = _.get(res, "currentBreakpoint", null);
    const increment = _.get(sizes, `size_${currentBreakpoint}.increment`, 0);
    let activeIndex = _.get(res, "realIndex", null);
    activeIndex += increment;
    if (activeIndex > list.length - 1) {
      activeIndex -= list.length;
    }
    const selectedSlide = _.get(list, activeIndex);
    setActiveSlide(selectedSlide);
  };

  return (
    <div className="relative w-full">
      <div className="ml-4 mt-6 partner-swiper-prev text-white hover:text-cape-cod-500 z-10">
        <i className="fas fa-chevron-left fa-3x" />
      </div>
      <div className="mr-4 mt-6 partner-swiper-next text-white hover:text-cape-cod-500 z-10">
        <i className="fas fa-chevron-right fa-3x" />
      </div>
      <Swiper
        id="partner"
        tag="section"
        wrapperTag="div"
        onSlideChange={handleOnSlide}
        loop
        effectfade="true"
        navigation={{
          prevEl: ".partner-swiper-prev",
          nextEl: ".partner-swiper-next",
        }}
        autoplay={{
          "delay": 5000,
          "disableOnInteraction": false
        }}
        spaceBetween={30}
        slidesPerGroup={1}
        slidesPerView={5}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: sizes.size_400.perView,
          },
          640: {
            slidesPerView: sizes.size_640.perView,
          },
          1024: {
            slidesPerView: sizes.size_1024.perView,
          },
        }}
      >
        {slides}
        <div
          className="w-full"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <p className="text-center text-white">
            {_.get(activeSlide, "title")}
          </p>
        </div>
      </Swiper>
    </div>
  );
}

SwiperContainer.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
};

export default SwiperContainer;
