import React from "react";
import { useApiGet } from "react-reqq";
import * as actions from "./actions";
import * as c from "./constants";

export const useAuth = () => {
  const data = useApiGet(`${c.AUTHENTICATION}`, {});
  React.useEffect(() => {
    actions.checkAuth();
    if (data.isAuthenticated) {
      actions.myProfile();
    }
  }, [data.isAuthenticated]);
  return data;
};

export const useProfile = () => {
  const data = useApiGet(`${c.MY_PROFILE}`, {});
  React.useEffect(() => {
    actions.myProfile();
  }, []);

  return data;
};
