import React from "react";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import Loader from "modules/common/components/Loader";
import PrivateHeader from "./containers/PrivateHeader";
import PrivateFooter from "./containers/PrivateFooter";

const DashboardContainer = React.lazy(() =>
  import("modules/dashboard/containers/DashboardContainer")
);
const TransactionContainer = React.lazy(() =>
  import("modules/transaction/containers/TransactionContainer")
);
const ForDevelopersContainer = React.lazy(() =>
  import("modules/developers/containers/ForDevelopersContainer")
);
const ContactUsContainer = React.lazy(() =>
  import("modules/contact-us/containers/ContactUsContainer")
);
const ProfileContainer = React.lazy(() =>
  import("modules/auth/containers/ProfileContainer")
);

const ChangePassword = React.lazy(() =>
  import("modules/auth/containers/ChangePassword")
);

const APIKeyContainer = React.lazy(() =>
  import("modules/api-key/containers/APIKeyContainer")
);

const TermsConditionContainer = React.lazy(() =>
  import("./containers/TermsConditionContainer")
);

function PrivateRoute() {
  const match = useRouteMatch();
  return (
    <React.Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <div className="flex flex-col h-screen">
        <Switch>
          <Route path={`${match.path}dashboard`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <DashboardContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}transaction`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <TransactionContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}developers`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <ForDevelopersContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}api-keys`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <APIKeyContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}contact-us`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <ContactUsContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}profile`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <ProfileContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}change-password`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <ChangePassword />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Route path={`${match.path}terms-condition`}>
            <>
              <PrivateHeader />
              <main className="flex-grow">
                <TermsConditionContainer />
              </main>
              <PrivateFooter />
            </>
          </Route>
          <Redirect to={`${match.path}dashboard`} />
        </Switch>
      </div>
    </React.Suspense>
  );
}

export default PrivateRoute;
