import * as React from "react";
import _ from "lodash";

export const storage = {
  get: (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return false;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const useDocumentTitle = (title) => {
  const [doctitle, setDocTitle] = React.useState(
    JSON.parse(sessionStorage.getItem("tittle")) || title
  );
  React.useEffect(() => {
    sessionStorage.setItem("title", doctitle);
    return () => {
      sessionStorage.removeItem("title");
    };
  }, [doctitle, title]);

  document.title = doctitle;
  return [doctitle, setDocTitle];
};

export const usePersistState = (key, state) => {
  const [value, setValue] = React.useState(storage.get(key) || state);
  const updateState = React.useCallback(
    _.debounce((newState) => {
      storage.set(key, newState);
    }, 200),
    []
  );
  React.useEffect(() => {
    updateState(value);
    return () => {
      storage.remove(key);
    };
  }, [key, updateState, value]);
  return [value, setValue];
};
