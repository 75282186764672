import React from "react";
import PropTypes from "prop-types";
import logo from "assets/images/logo-white-text.svg";
import { NavLink, useHistory } from "react-router-dom";
import SignInContainer from "modules/auth/containers/SignInContainer";
import NewUpdateContainer from "modules/common/components/NewUpdateContainer";

const navigation = [
  {
    url: "/features",
    label: "HOME",
  },
  {
    url: "/developers",
    label: "FOR DEVELOPERS",
  },
  {
    url: "/contact-us",
    label: "CONTACT US",
  },
  {
    url: "/track-order",
    label: "TRACK ORDER",
  },
];

function Header({ size }) {
  const [toggle, setToggle] = React.useState(false);
  const history = useHistory();

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setToggle(!value);
  };

  const handleOnClick = (url) => (e) => {
    e.preventDefault();
    setToggle(false);
    history.push(url);
  };

  return (
    <header className="fixed top-0 w-full z-20">
      <nav className="h-16 flex items-center py-2 lg:py-4 px-4 md:px-10 lg:px-16 main-header">
        <a href="/">
          <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-0">
            <img src={logo} alt="" />
          </div>
        </a>
        {size === "md" && (
          <>
            <div className="block lg:hidden flex flex-grow justify-end">
              <div
                role="presentation"
                className={`hamburger${toggle ? " is-active" : ""}`}
                id="hamburger-1"
                onClick={handleOnToggle(toggle)}
              >
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </div>
            </div>
            <div className="hidden w-full lg:block flex-grow lg:flex lg:justify-end lg:items-center lg:w-auto ml-16 lg:ml-8 xl:ml-16">
              <div className="text-base xl:text-sm lg:text-xs">
                {navigation.map((nav) => (
                  <NavLink
                    key={nav.url}
                    to={nav.url}
                    activeClassName="text-koromiko-500"
                    className="block mt-4 mr-8 lg:inline-block lg:mt-0 text-white hover:text-kumera-500"
                  >
                    {nav.label}
                  </NavLink>
                ))}
              </div>
              <SignInContainer isShrink={toggle} />
            </div>
          </>
        )}
        {size === "sm" && (
          <div className="ml-auto my-3">
            <button
              type="button"
              className="disabled:opacity-75 disabled:cursor-wait focus:outline-none px-6 md:px-8 py-1 bg-kumera-500 hover:text-kumera-500 hover:bg-white text-white text-sm border-2 border-kumera-500 rounded-full flex-none"
              onClick={() => history.push("/features")}
            >
              <span className="hidden md:block">GO TO WEBSITE</span>
              <span className="block md:hidden">Back</span>
            </button>
          </div>
        )}
      </nav>
      {toggle && size === "md" && (
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto bg-cape-cod-500 p-6">
          <div className="text-md lg:flex-grow">
            {navigation.map((nav) => (
              <NavLink
                key={nav.url}
                to={nav.url}
                activeClassName="bg-koromiko-500"
                onClick={handleOnClick(nav.url)}
                className="block mt-4 py-2 px-5 rounded-md text-white"
              >
                {nav.label}
              </NavLink>
            ))}
          </div>
          <SignInContainer isShrink={toggle} />
        </div>
      )}
      <NewUpdateContainer />
    </header>
  );
}
Header.defaultProps = {
  size: "md",
};
Header.propTypes = {
  size: PropTypes.string,
};
export default Header;
