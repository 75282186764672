import React from "react";

export const IconDropdown = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20">
    <path fill="none" d="M0 0h20v20H0z" />
    <path
      d="M10.625 12.5a1 1 0 01-1.25 0L4.726 8.781A1 1 0 015.351 7h9.3a1 1 0 01.625 1.781z"
      fill="#fff"
    />
  </svg>
);

export const IconTruck = () => (
  <svg width={46} height={46} viewBox="0 0 46 46">
    <defs>
      <style>{".prefix__b{fill:#fff}"}</style>
    </defs>
    <path fill="none" d="M0 0h46v46H0z" />
    <path
      className="prefix__b"
      d="M36.118 25.591a.566.566 0 00-.566.566v3.964h-.9a3.575 3.575 0 00-7.106 0H15.752V13.714h19.8v1.779a.566.566 0 101.131 0v-2.345a.566.566 0 00-.566-.566H15.186a.566.566 0 00-.566.566v.566H8.137a2.576 2.576 0 00-2.573 2.573v.821a.566.566 0 00.566.566h.658l-.432 5.185a2.571 2.571 0 00-1.919 2.477v4.782a.566.566 0 00.566.566h1.145a3.674 3.674 0 007.326 0h1.145a.566.566 0 00.566.566h12.413a3.577 3.577 0 007 0h1.517a.566.566 0 00.566-.566v-4.528a.566.566 0 00-.563-.565zM9.811 32.949a2.546 2.546 0 112.546-2.546 2.546 2.546 0 01-2.546 2.546zm4.809-3.394h-1.131a.521.521 0 00-.1.02 3.673 3.673 0 00-7.154-.02h-.667v-4.219a1.443 1.443 0 011.441-1.441h7.61zm0-6.789H7.503l.424-5.092h6.693zm0-6.223H6.7v-.256a1.443 1.443 0 011.437-1.441h6.479zm18.917 14.121a2.44 2.44 0 01-2.441 2.3 2.445 2.445 0 01-2.4-2.027.509.509 0 00-.029-.55 2.441 2.441 0 114.873.274z"
    />
    <path
      className="prefix__b"
      d="M35.552 17.109h-7.92a.566.566 0 100 1.131h7.92a.566.566 0 100-1.131zM36.684 23.332H24.238a.566.566 0 100 1.131h12.446a.566.566 0 100-1.131zM35.552 19.937h-6.223a.566.566 0 000 1.131h6.223a.566.566 0 000-1.131zM37.815 18.24h1.134a.566.566 0 100-1.131h-1.132a.566.566 0 000 1.131zM39.515 23.332h-1.131a.566.566 0 100 1.131h1.131a.566.566 0 100-1.131zM40.078 19.937h-2.263a.566.566 0 000 1.131h2.263a.566.566 0 100-1.131z"
    />
  </svg>
);

export const IconBox = () => (
  <svg width={46} height={45} viewBox="0 0 46 45">
    <defs>
      <style>{".prefix__b{fill:#fff}"}</style>
    </defs>
    <path fill="none" d="M0 0h46v45H0z" />
    <path
      className="prefix__b"
      d="M38.958 13.379L23.644 5.987a.494.494 0 00-.43 0L7.901 13.379a.494.494 0 00-.279.445v17.954a.494.494 0 00.279.445l15.313 7.393a.494.494 0 00.43 0l15.313-7.393a.494.494 0 00.279-.445V13.824a.494.494 0 00-.278-.445zm-15.528-6.4l14.176 6.845-4.109 1.984a.49.49 0 00-.084-.053L19.332 8.958zm-5.213 2.538l14.156 6.834-2.9 1.4-14.15-6.832zm14.487 7.771v5.174l-2.708 1.307v-5.174zm5.544 14.18l-14.325 6.915V21.527l3.417-1.65a.494.494 0 10-.43-.89l-3.481 1.681-1.37-.661a.494.494 0 10-.43.89l1.305.63v16.856L8.61 31.468V14.611l10.909 5.267a.494.494 0 00.43-.89l-10.7-5.164 4.911-2.369 14.841 7.165.007.009v5.929a.494.494 0 00.709.445l3.7-1.784a.494.494 0 00.279-.445v-5.963l4.556-2.2z"
    />
    <path
      className="prefix__b"
      d="M12.683 29.556l-2.247-1.085a.494.494 0 10-.43.89l2.247 1.085a.494.494 0 00.43-.89zM14.755 28.205l-4.317-2.081a.494.494 0 00-.43.89l4.312 2.082a.494.494 0 00.43-.89z"
    />
  </svg>
);
